import { render, staticRenderFns } from "./AboutCN.vue?vue&type=template&id=d474460c&scoped=true"
import script from "./AboutCN.vue?vue&type=script&lang=js"
export * from "./AboutCN.vue?vue&type=script&lang=js"
import style0 from "./AboutCN.vue?vue&type=style&index=0&id=d474460c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d474460c",
  null
  
)

export default component.exports